import React, { Component, Fragment } from 'react'
import { Home, ArrowLeft } from 'react-feather'
import { Link } from 'gatsby'
import { SEO, Layout, HMOSlider } from '../../components/common'
import { Container, Row, Col, Breadcrumb, Card, Image } from 'react-bootstrap'
import moment from 'moment'

class OurService extends Component {
   constructor(props) {
    super(props);
    this.state = { 
      addClass: false,
      name: null, 
      slug: null, 
      description: null, 
      services_avatar: null,
      facility_services: []
    }
  }

  toggle() {
    this.setState({addClass:  !this.state.addClass});
  }

  render() {
    let contentClass = ["content"];
    if (this.state.addClass) {
      contentClass.push('ellipsis-0');
    }
   
    const { avatar, name, slug, description, facility_services, services_avatar } = this.props.location.state === undefined ? this.state : this.props.location.state
    const data = this.props.pageContext
    const { favicon } = data.topNav

    const clinics = facility_services === [] ? [] : facility_services.map(e => e.clinics).flat()
    const departments = facility_services === [] ? [] : facility_services.map(e => e.departments).flat()

    const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
    return (
      <>
        <SEO title={"Service - " + name } favicon={favicon} />
      <Layout activeNav='our-services' data={data.topNav}>
          <Container className="my-4 md-mt-10 sm-mt-7">
            <Breadcrumb className="breadcrumb-wrapper mx-0">
              <Breadcrumb.Item href="/">
                <Home className="text-violet mr-2" size={14} />
                Home
            </Breadcrumb.Item>
              <Breadcrumb.Item href="/our-services">
                Our Services
            </Breadcrumb.Item>
              <Breadcrumb.Item active>{name}</Breadcrumb.Item>
            </Breadcrumb>
            <Card className="my-3">
              <Card.Body className="d-flex flex-row align-items-center border-bottom">
                <Link to="/our-services">
                  <ArrowLeft className="text-violet mr-2" size={14} />
                </Link>
                <p className="font-weight-bold mb-0">List of Service</p>
              </Card.Body>
              <Card.Body>
                <Row>
                  <Col lg={4} md={12} className="d-flex flex-column align-items-center justify-contents-center py-5 px-0 border-right">
                    <Image src={(avatar || services_avatar ) || "https://seeyoudoc.s3-ap-southeast-1.amazonaws.com/assets/d-image.svg"} alt={slug || "service-avatar"} className="rounded-circle avatar-mmd mb-3" onError={(e)=>{e.target.onerror = null; e.target.src="https://s3-ap-southeast-1.amazonaws.com/seeyoudoc-mobile/version+3/image.svg"}} />
                    <h6 className="font-weight-bold px-4">{name}</h6>
                  </Col>
                  <Col lg={8} md={12} className="my-5 pl-5">
                    <h6>Service details</h6>
                    <div className="text-editor" dangerouslySetInnerHTML={{ __html: description }} />
                    <h6>Department details</h6>

                    {departments && departments.length > 0
                      ? departments.map((dept, i) => <Fragment key={i}>
                        <p className="text-muted my-3">{"Name: " + dept.name || "Not Available"}</p>
                        <p className="text-muted mb-3">{dept.room_no
                          ? "Room: " + dept.room_no
                          : "Not Available"}</p>

                      </Fragment>)
                      : "Not Available"}
                    <h6 className="mt-5">Clinic schedule</h6>
                    {clinics && clinics.length > 0
                      ? clinics.map((e, i) => {
                        return (
                          <Fragment key={i}>
                            <p className="text-muted mb-3">Name: {e.name}</p>
                            {days.map((item, i) => {
                              if (e.schedules !== undefined && e.schedules.find(elem => elem.day === item)) {
                                return (<Row className="border-bottom py-1" key={i}>
                                  <Col lg={6} sm={6}><p className="text-muted mb-2 text-capitalize">{item}</p></Col>
                                  <Col lg={6} sm={6}>
                                    {e.schedules.map((sched, i) => sched.active && sched.day === item
                                      ? <Col lg={12} sm={12} key={i}><p className="text-muted mb-2">{moment(sched.start_time).format('LT')} - {moment(sched.end_time).format('LT')}</p></Col>
                                      : null)}
                                  </Col>
                                </Row>)
                              } return null
                            })}
                          </Fragment>
                        )
                      }) : "Not Available"}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
          <HMOSlider data={data.accreditedHmos} />
        </Layout>
      </>
    )
  }
}

export default OurService 
